<template>
  <section v-loading="loading" class="page">
    <div>
      <div class="title">
        По данному авто время<br />таймслота еще не наступило
      </div>
      <TimeslotCard :timeslot="timeslot" :highlight="true" />
      <div :class="['flex', { center: hasIncompleteParking }]">
        <MainButton
          v-if="!hasIncompleteParking"
          title="Подтвердить приезд заранее"
          type="primary"
          size="big"
          width="half"
          @button-clicked="isModalOpen = true"
        />
        <MainButton
          title="Понятно"
          type="secondary"
          size="big"
          width="half"
          @button-clicked="$router.push({ name: 'terminal-carNumber' })"
        />
      </div>
    </div>

    <TechSupport />

    <CommonQueueModal
      v-if="isModalOpen"
      title="Вы уверены, что хотите подтвердить проезд заранее?"
      description="Напоминаем, что при подтверждении приезда заранее - вы регистрируетесь в общей очереди. Вы будете вызваны на выгрузку при отсутствии на парковке авто, приехавших по забронированным таймслотам."
      cancel="Отменить"
      accept="Подтвердить"
      @cancel="isModalOpen = false"
      @accept="moveToHand"
    />
  </section>
</template>

<script>
import { GET_IS_INCOMPLETE_PARKING } from '@/store/actions'
import { mapGetters } from 'vuex'
import CommonQueueModal from '@/views/terminalUI/components/modals/CommonQueueModal'
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import TechSupport from '@/views/terminalUI/components/TechSupport'
import TimeslotCard from '@/views/terminalUI/components/TimeslotCard'
import formattedPhoneMixin from '@/views/terminalUI/mixins/formattedPhoneMixin'
import moment from 'moment-timezone'

import Api from '@/api'
export default {
  name: 'EarlyArrived',
  components: { CommonQueueModal, MainButton, TimeslotCard, TechSupport },
  mixins: [formattedPhoneMixin],
  data() {
    return {
      isModalOpen: false,
      timeslot: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({ hasIncompleteParking: GET_IS_INCOMPLETE_PARKING }),
  },

  created() {
    this.timeslot = this.$route.params.timeslot
  },
  methods: {
    async moveToHand() {
      this.loading = true
      // если ТС не сегодняшний - проверяем, не исчерпан ли лимит ручных ТС на день
      // Если нет или ТС сегодняшний - переносим в общую очередь
      // иначе уходим на страницу сообщения о недоступности ручных ТС до конца дня
      try {
        let canTakeHand = true

        if (!moment().isSame(this.timeslot.window_from, 'day')) {
          const { data } = await Api.timeslots.checkHandLimit({
            unload_id: this.timeslot.unload_id,
          })

          canTakeHand = Boolean(data['manual-ts-left'])
        }

        if (canTakeHand) {
          await Api.timeslots.timeslotPush({ timeslot_id: this.timeslot.id })

          await this.$router.push({
            name: 'terminal-finallyHandResponse',
            params: {
              phone: this.formattedPhone,
              plate_truck: this.timeslot.plate_truck,
            },
          })
        } else {
          await this.$router.push({
            name: 'terminal-limitHandResponse',
            params: { hasTimeslot: true },
          })
        }
      } catch (e) {
        console.log(e.response.data)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.page
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 60px 11% 20px
  .title
    margin-bottom: 40px
    font-family: $fontPrimary
    font-size: 48px
    font-weight: 500
    line-height: 56px
  .flex
    display: flex
    gap: 32px
    &.center
      justify-content: center
</style>
