var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page",
    },
    [
      _c(
        "div",
        [
          _vm._m(0),
          _c("TimeslotCard", {
            attrs: { timeslot: _vm.timeslot, highlight: true },
          }),
          _c(
            "div",
            { class: ["flex", { center: _vm.hasIncompleteParking }] },
            [
              !_vm.hasIncompleteParking
                ? _c("MainButton", {
                    attrs: {
                      title: "Подтвердить приезд заранее",
                      type: "primary",
                      size: "big",
                      width: "half",
                    },
                    on: {
                      "button-clicked": function ($event) {
                        _vm.isModalOpen = true
                      },
                    },
                  })
                : _vm._e(),
              _c("MainButton", {
                attrs: {
                  title: "Понятно",
                  type: "secondary",
                  size: "big",
                  width: "half",
                },
                on: {
                  "button-clicked": function ($event) {
                    return _vm.$router.push({ name: "terminal-carNumber" })
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("TechSupport"),
      _vm.isModalOpen
        ? _c("CommonQueueModal", {
            attrs: {
              title: "Вы уверены, что хотите подтвердить проезд заранее?",
              description:
                "Напоминаем, что при подтверждении приезда заранее - вы регистрируетесь в общей очереди. Вы будете вызваны на выгрузку при отсутствии на парковке авто, приехавших по забронированным таймслотам.",
              cancel: "Отменить",
              accept: "Подтвердить",
            },
            on: {
              cancel: function ($event) {
                _vm.isModalOpen = false
              },
              accept: _vm.moveToHand,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _vm._v(" По данному авто время"),
      _c("br"),
      _vm._v("таймслота еще не наступило "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }